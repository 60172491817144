
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import axios from 'axios';

import ImportFilesSelector from './ImportFilesSelector.vue';
import RelationCloneConfig from './RelationCloneConfig.vue';
import CloneResult from './CloneResult.vue';
import { BModal } from 'bootstrap-vue';
import { CrudList } from '@gid/vue-common/store/shared/crud-list.module';
import { DataUiBlueprints } from '@gid/models';

export interface CurrentScreenUrl {
  query: string;
  queryString: string;
  noPagingQuery: string;
  noPagingQueryString: string;
  entityPath: string;
}

@Component({
  components: {
    ImportFilesSelector,
    RelationCloneConfig,
    CloneResult,
  },
})
export default class CloneModal extends Vue {
  @Prop({ required: true }) currentScreenUrl!: CurrentScreenUrl;
  @Prop({ type: Object }) readonly store!: CrudList<any>;
  @Ref('modal') readonly modal!: BModal;

  importStep = 0;
  inProgress = false;
  importing = false;
  relConfig: any = null;
  cloneResult: any = null;
  importStepsActions = ['Clone', 'Close'];
  error: any = null;

  get cloneUrl() {
    console.log(
      'CLUR',
      `/data-api/clone/${this.currentScreenUrl.entityPath}?${this.currentScreenUrl.noPagingQueryString}`,
    );
    return `/data-api/clone/${this.currentScreenUrl.entityPath}?${this.currentScreenUrl.noPagingQueryString}`;
  }

  get modelConstructor() {
    return DataUiBlueprints.getEntityConstructor(
      this.store.entityConstructorName,
    );
  }

  get cloneActionAllowed() {
    return true;
  }

  get cloneConfigValid() {
    return true;
  }

  created() {
    this.resetCloneModal();
  }

  open() {
    this.resetCloneModal();
    this.modal.show();
  }
  resetCloneModal() {
    this.importStep = 0;
    this.inProgress = false;
    this.cloneResult = null;
    this.error = null;
  }
  modalClosed() {
    this.resetCloneModal();
    this.$emit('close');
  }
  importModalAction() {
    switch (this.importStep) {
      case 0:
        return this.doClone();
      default:
        this.modal.hide();
    }
  }

  onRelConfig(e) {
    this.relConfig = e;
  }

  async doClone() {
    this.inProgress = true;
    try {
      const result = await axios.post(this.cloneUrl, this.relConfig);
      this.cloneResult = result.data;
      this.$nextTick(() => {
        this.importStep = 1;
      });
    } catch (error: any) {
      this.error = {
        message: error.toString(),
        details: error,
      };
      this.$emit('error', {
        data: error,
        title: 'Clone failed',
      });
    } finally {
      this.error = null;
      this.inProgress = false;
    }
  }
}
