
import {
  DataUiBlueprints,
  SimplifiedRelationType,
  SimplifiedColumnType,
} from '@gid/models';
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';

@Component({
  name: 'CloneResult',
})
export default class CloneResult extends Vue {
  @Prop({ required: true, type: String }) relation!: string;
  @Prop({ required: true, type: Object }) result: any;
}
