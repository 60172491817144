
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { DataUiBlueprints } from '@gid/models';
import { CrudList } from '@gid/vue-common/store/shared/crud-list.module';
import BulkEditFields from './BulkEditFields.vue';
import CloneResult from './CloneResult.vue';
import type { Transformer } from './BulkEditFields.vue';

enum TABS {
  configure,
  results,
}

type Actions = 'Save' | 'Close';

export interface CurrentScreenUrl {
  query: string;
  queryString: string;
  noPagingQuery: string;
  noPagingQueryString: string;
  entityPath: string;
}

@Component({
  components: {
    BulkEditFields,
    CloneResult,
  },
})
export default class CloneModal extends Vue {
  @Prop({
    required: true,
  })
  currentScreenUrl!: CurrentScreenUrl;
  @Prop({
    type: Object,
  })
  readonly store!: CrudList<any>;
  @Ref('modal')
  readonly modal!: BModal;

  currentTab = TABS.configure;
  isLoading = false;
  editFields: Transformer[] = [];
  results: number | null = null;
  importStepsActions: Actions[] = ['Save', 'Close'];
  error: unknown = null;
  allIds: string[] = [];

  get modelConstructor() {
    return DataUiBlueprints.getEntityConstructor(
      this.store.entityConstructorName,
    );
  }

  get cloneConfigValid() {
    return true;
  }

  getAllIds() {
    this.isLoading = true;
    this.store
      .FETCH_ALL_ITEMS_IDS()
      .then((data) => {
        this.allIds = data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  open() {
    this.resetModal();
    this.modal.show();
    this.getAllIds();
  }

  resetModal() {
    this.currentTab = TABS.configure;
    this.isLoading = false;
    this.results = null;
    this.error = null;
  }

  closeModal() {
    this.resetModal();
    this.$emit('close');
  }

  modalAction() {
    if (this.currentTab === TABS.configure) {
      return this.updateFields();
    } else {
      return this.modal.hide();
    }
  }

  setEditFields(editFields: Transformer[]) {
    this.editFields = editFields;
  }

  async updateFields() {
    let editFieldsDTO = {};

    this.editFields.forEach((ef) => {
      editFieldsDTO = {
        ...editFieldsDTO,
        [ef.fieldName]: ef.newValue,
      };
    });

    const mappedDataWithIds = this.allIds.map((id) => ({
      id,
      ...editFieldsDTO,
    }));

    try {
      this.isLoading = true;
      const res = await this.store.ITEMS_UPDATE_ITEMS({
        data: mappedDataWithIds,
        updatedFields: Object.keys(editFieldsDTO),
      });
      this.results = res;
    } catch (error: any) {
      this.error = {
        message: error.toString(),
        details: error,
      };
      this.$emit('error', {
        data: error,
        title: 'Bulk edit failed',
      });
    } finally {
      this.error = null;
      this.isLoading = false;

      setTimeout(() => {
        this.currentTab = TABS.results;
      }, 20);
    }
  }
}
