
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import { Vue, Component, Ref, Prop, Watch } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import axios from 'axios';
import { appUrl } from '@gid/vue-common/app-settings/appUrls';
import CrudListPagination from '@gid/vue-common/components/crud-list/CrudListPagination.vue';
import CrudListTable from '@gid/vue-common/components/crud-list/CrudListTable.vue';
import CrudListErrorToast from '@gid/vue-common/components/crud-list/CrudListErrorToast.vue';
import CrudEditForm from '@gid/vue-common/components/crud-list/CrudEditForm.vue';
import CrudListFilter from '@gid/vue-common/components/crud-list/CrudListFilter.vue';
import CrudDefaultFilter from '@gid/vue-common/components/crud-list/CrudDefaultFilter.vue';
import CrudSelectionActions from '@gid/vue-common/components/crud-list/CrudSelectionActions.vue';
import CrudRelationLinks from '@gid/vue-common/components/crud-list/CrudRelationLinks.vue';
import {
  updateModelOnInput,
  getRelationNameFormatter,
  getEntityNameFormatter,
} from '@gid/vue-common/components/crud-list/crud-list-util';
import ImportExportMenu from '../importer/ImportExportMenu.vue';
import {
  getOrCreateDynamicModuleForEntity,
  ConstructorOfDynamicModule,
} from '@gid/vue-common/store/shared/dynamic.module';
import { CreateQueryParams } from '@dataui/crud-request';
import { getModule } from 'vuex-module-decorators';
import {
  CrudList,
  ModelWithId,
} from '@gid/vue-common/store/shared/crud-list.module';
import { Connection } from 'typeorm';
import { DataUiBlueprints, SimplifiedRelationType } from '@gid/models';
import { sentenceCase } from 'change-case';
import { mapGetters } from 'vuex';
import { plainToClass } from 'class-transformer';
import { validate, ValidationError } from 'class-validator';
import { BIcon, BIconArrowUp } from 'bootstrap-vue';

interface TabDescriptor<Model extends ModelWithId> {
  id?: string;
  entityId: any;
  label: string;
  model: Model;
  initialModel?: Model;
  saving: boolean;
  saveTime?: Date;
  dirty: boolean;
  formErrors?: FormErrors<Model>;
}

interface FormErrors<Model extends ModelWithId> {}

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    CrudListTable,
    CrudListErrorToast,
    CrudListPagination,
    CrudEditForm,
    CrudListFilter,
    CrudDefaultFilter,
    CrudSelectionActions,
    CrudRelationLinks,
    ImportExportMenu,
    BIcon,
    BIconArrowUp,
  },
  computed: mapGetters(['environment']),
})
export default class CrudEntityEditor<Model extends ModelWithId> extends Vue {
  @Ref('table') readonly table;
  @Prop() readonly entity!: string;
  @Prop() readonly entityIdsList!: string;
  environment!: string;

  model!: ConstructorOfDynamicModule<Model>;
  modelName!: string;
  inProgress: boolean = false;
  connection!: Connection;
  tabs: TabDescriptor<Model>[] = [];
  activeTabIndex: number = 0;
  advancedSearch: boolean = false;
  quickSearch: any = '';
  readOnlyView: boolean = false;
  showButton: boolean = false;
  nameFormatter!: (Model) => string;

  valueForField(item, column) {
    return item && _.get(item, column);
  }

  created() {
    this.model = DataUiBlueprints.getEntityConstructor(
      this.entity,
    ) as ConstructorOfDynamicModule<Model>;
    const blueprint = DataUiBlueprints.get(this.model);
    this.modelName = blueprint?.name?.name ?? this.model.name;
    const hasWritePermissions =
      this.contactProfiles &&
      blueprint.permissions.write.some((p) => this.contactProfiles.includes(p));
    this.readOnlyView = blueprint?.type === 'view' || !hasWritePermissions;
    this.nameFormatter = getEntityNameFormatter(this.model);
    this.loadDocumentList();
  }

  mounted() {
    this.observeScrolling();
  }

  observeScrolling() {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 1],
    };

    const observer = new IntersectionObserver(
      this.handleScroll,
      observerOptions,
    );

    observer.observe(this.$refs.topAnchor as HTMLElement);
  }

  handleScroll(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      this.showButton = !entry.isIntersecting;
    });
  }

  /**
   *  Scroll to top function with customizable time of execution.
   * @param {number} duration - Takes the duration of the animation in milliseconds
   */
  scrollToTop(duration: number) {
    const start = window.scrollY;
    const startTime =
      'now' in window.performance ? performance.now() : new Date().getTime();

    function scroll() {
      const now =
        'now' in window.performance ? performance.now() : new Date().getTime();
      const timeElapsed = now - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      window.scrollTo(0, start - start * progress);

      if (progress < 1) {
        requestAnimationFrame(scroll);
      }
    }

    requestAnimationFrame(scroll);
  }

  updateTabInput(tab: TabDescriptor<Model>, $event) {
    updateModelOnInput(tab.model, $event);
    tab.dirty = !_.isEqual(
      tab.entityId ? this.store.getItem(tab.entityId) : tab.initialModel,
      tab.model,
    );
  }

  beforeRouteLeave(to, from, next) {
    const dirty = this.tabs.reduce((acc, tab) => acc || tab.dirty, false);
    if (!dirty) {
      next();
      return;
    }

    if (confirm('You have unsaved changes. Discard?')) {
      next();
    } else {
      next(false);
    }
  }

  // Computed
  get store(): CrudList<Model> {
    const blueprint = DataUiBlueprints.get(this.model);

    const moduleClass = getOrCreateDynamicModuleForEntity<
      ConstructorOfDynamicModule<CrudList<Model>>
    >(this.$store, this.model, blueprint.endpoint);
    const store = getModule(moduleClass, this.$store);
    store.pagination.sortBy = 'id';
    store.pagination.sortDir = true;
    return store;
  }

  get contactProfiles() {
    return this.$store.state.auth.user?.contact?.profiles;
  }

  get entityIds() {
    return this.entityIdsList ? this.entityIdsList.split(',') : null;
  }

  get isSingleItemView() {
    return this.entityIds ? this.entityIds.length == 1 : false;
  }

  get columns() {
    const tableBlueprint = DataUiBlueprints.get(this.model)?.groups.table;

    if (!tableBlueprint) {
      throw new Error('Failed to find DataUi blueprint for ' + this.model);
    }

    return Object.entries(tableBlueprint)
      .filter(([columnName, config]) => !config.hide)
      .map(([columnName, config]) => {
        const label = config.label ?? sentenceCase(columnName);
        let formatter, relationPath, multiple;
        if (config.isRelation) {
          formatter = getRelationNameFormatter(config);
          relationPath =
            typeof config.relationEntity === 'string'
              ? config.relationEntity
              : config.relationEntity.name;
          multiple =
            config.relationType === SimplifiedRelationType.MANY_TO_MANY ||
            config.relationType === SimplifiedRelationType.ONE_TO_MANY;
        }
        if (config.columnType.type === 'datetime') {
          formatter = (item) => (item ? moment(item).format('L') : item);
        }
        if (config.isArray) {
          const singleItemFormatter = formatter ?? ((x) => x);
          formatter = (item) => item?.map(singleItemFormatter).join(', ');
        }
        return {
          key: columnName,
          label,
          sortable: !config.isRelation,
          formatter,
          config,
          relationPath,
          multiple,
        };
      });
  }

  get actions() {
    const actions = DataUiBlueprints.get(this.model)?.groupActions;

    const filteredActions = actions?.filter((action) =>
      this.contactProfiles.includes(_.startCase(action)),
    );

    if (!filteredActions || !filteredActions?.length) return null;

    return {
      key: 'actions',
      label: 'Actions',
      actions: filteredActions.map((action) => ({
        callback: this[action],
        buttonText: _.startCase(action),
      })),
    };
  }
  get description() {
    return DataUiBlueprints.get(this.model)?.description;
  }

  onFilterChange(event) {
    this.loadDocumentList(event);
  }
  throttledOnFilterChange = _.debounce(this.onFilterChange, 400);

  onQuickSearch(event) {
    this.quickSearch = event;
    this.throttledOnFilterChange(event);
  }

  @Watch('advancedSearch')
  onAdvancedSearch() {
    if (!this.advancedSearch) {
      this.onQuickSearch(this.quickSearch);
    }
  }

  @Watch('entityIds')
  onEntityId() {
    this.loadDocumentList();
  }

  addTab(tabWithoutId: TabDescriptor<Model>) {
    if (tabWithoutId.entityId) {
      const tabIndex = this.tabs.findIndex(
        (t) => t.entityId === tabWithoutId.entityId,
      );
      if (tabIndex >= 0 && tabIndex + 1 <= this.tabs.length) {
        this.switchTab(tabIndex + 1);
        return;
      }
    }
    const tab: TabDescriptor<Model> | undefined = {
      ...tabWithoutId,
      id: new Date().getTime().toString() + '.' + Math.random().toString(),
    };
    this.tabs.push(tab);
  }

  switchTab(tabIndex: number) {
    this.activeTabIndex = tabIndex;
  }

  onTabsChanged(currentTabs, previousTabs) {
    if (previousTabs?.length <= currentTabs?.length) {
      this.switchTab(this.tabs.length);
    }
  }

  async closeTab(tab: TabDescriptor<Model>) {
    const tabIndex = this.tabs.findIndex(
      (t) => tab.label === t.label && tab.entityId === tab.entityId,
    );
    if (tab.dirty) {
      const response = await this.$bvModal.msgBoxConfirm(
        `Discard unsaved changes`,
        {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        },
      );
      if (response !== true) {
        return;
      }
    }
    if (tabIndex >= 0) {
      this.tabs.splice(tabIndex, 1);
    }
  }

  updateTabLabel(tab: TabDescriptor<Model>): TabDescriptor<Model> {
    if (tab.entityId) {
      tab.label = this.nameFormatter(tab.model);
    } else {
      tab.label = 'New ' + this.modelName;
    }
    return tab;
  }

  newItem() {
    this.addTab(
      this.updateTabLabel({
        entityId: null,
        label: '',
        model: DataUiBlueprints.getEntityInstance(this.model),
        initialModel: DataUiBlueprints.getEntityInstance(this.model),
        saving: false,
        dirty: false,
        formErrors: {},
      }),
    );
  }

  editItem(item) {
    this.addTab(
      this.updateTabLabel({
        entityId: item.id,
        label: '',
        model: _.cloneDeep(item),
        saving: false,
        dirty: false,
        formErrors: {},
      }),
    );
  }

  cloneItem(item) {
    const model = DataUiBlueprints.cloneEntityInstance(this.model, item);
    this.addTab(
      this.updateTabLabel({
        entityId: null,
        label: '',
        model,
        saving: false,
        dirty: true,
      }),
    );
  }

  editSelection() {
    this.store.selectedItems.forEach((item) => this.editItem(item));
  }

  cloneSelection() {
    this.store.selectedItems.forEach((item) => this.cloneItem(item));
  }

  async deleteSelection() {
    const response = await this.$bvModal.msgBoxConfirm(
      `You are about to delete ${this.store.selectedItems.length} items!`,
      {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      },
    );
    if (response !== true) {
      return;
    }
    this.store.selectedItems.map((item) =>
      this.store.ITEMS_DELETE_ITEM({ id: item.id }),
    );
  }

  revertChangesInTab(tab: TabDescriptor<Model>) {
    if (tab.entityId) {
      tab.model = _.cloneDeep(this.store.getItem(tab.entityId));
    } else {
      tab.model = new this.model();
    }
    tab.dirty = false;
    this.updateTabLabel(tab);
  }

  async saveTab(tab: TabDescriptor<Model>) {
    const modelData = plainToClass(this.model, tab.model);
    const formHasErrors = await validate(modelData);
    if (formHasErrors.length) {
      tab.formErrors = this.formatValidationErrors(formHasErrors);
      return;
    }
    tab.formErrors = {}; // reset errors
    return this.saveModel(tab);
  }

  saveModel(tab: TabDescriptor<Model>) {
    const payload = {
      data: tab.model,
    };
    tab.saving = true;
    let promise;
    if (tab.entityId) {
      promise = this.store.ITEMS_UPDATE_ITEM(payload);
    } else {
      promise = this.store.ITEMS_CREATE_ITEM(payload);
    }
    promise
      .then((entityId) => {
        if (!this.store.error) {
          tab.entityId = entityId;
          this.revertChangesInTab(tab);
          tab.saveTime = new Date();
        }
      })
      .finally(() => {
        tab.saving = false;
        this.refreshList();
      });
  }
  /**
   * formats Validation messages from class-validator
   *
   * **/
  formatValidationErrors(errors: ValidationError[], prefix = '') {
    const formattedErrors: any = {};

    errors.forEach((error) => {
      const propertyKey = prefix
        ? `${prefix}.${error.property}`
        : error.property;

      if (error.constraints) {
        formattedErrors[propertyKey] = Object.values(error.constraints);
      }

      if (error.children && error.children.length > 0) {
        const nestedErrors = this.formatValidationErrors(
          error.children,
          propertyKey,
        );
        Object.assign(formattedErrors, nestedErrors);
      }
    });

    return formattedErrors;
  }

  loadDocumentList(search?) {
    if (this.entityIds?.length) {
      search = {
        $and: [{ id: { $in: this.entityIds } }, search],
      };
    }
    const query: CreateQueryParams = {
      page: this.store.pagination.currentPage,
      limit: this.store.pagination.perPage,
      search,
    };
    this.store
      .ITEMS_FETCH({
        query,
      })
      .then(() => {
        console.log('>>', this.isSingleItemView);
        if (this.isSingleItemView) {
          this.editItem(this.store.currentPageItems[0]);
        }
      });
  }

  refreshList() {
    this.store.ITEMS_RELOAD();
  }

  clearEntityId() {
    this.$router.push(`/data-ui/${this.model.name}`, () => {
      this.refreshList();
    });
  }

  async loginAsUser(user) {
    const userRole = user?.account?.role;
    try {
      const {
        data: { refresh_token },
      } = await axios.post('/api/admin/refresh-token-for', {
        email: user.email,
        role: userRole,
        contact_sfid: user.id,
      });

      const url = appUrl(userRole, `/login?token=${refresh_token}`);

      window.open(url, '_blank');
    } catch (error) {
      console.error(error);
      this.$bvToast.toast(`Could not login user: ${user.name}`, {
        title: 'Error',
        variant: 'danger',
        solid: true,
        noAutoHide: true,
      });
    }
  }
}
