
import ComponentWithPathWrapper from '@gid/vue-common/components/crud-list/ComponentWithPathWrapper.vue';
import CrudCheckBox from '@gid/vue-common/components/crud-list/CrudCheckBox.vue';
import CrudNumberInput from '@gid/vue-common/components/crud-list/CrudNumberInput.vue';
import CrudEnumPicker from '@gid/vue-common/components/crud-list/CrudEnumPicker.vue';
import CrudHtmlEditor from '@gid/vue-common/components/crud-list/CrudHtmlEditor.vue';
import CrudFileInput from '@gid/vue-common/components/crud-list/CrudFileInput.vue';
import CrudRichTextEditRichTextEditor from '@gid/vue-common/components/crud-list/CrudRichTextEditor.vue';
import CrudRelationLinks from '@gid/vue-common/components/crud-list/CrudRelationLinks.vue';
import CrudRelationQuickLink from '@gid/vue-common/components/crud-list/CrudRelationQuickLink.vue';
import CrudJsonEditor from '@gid/vue-common/components/crud-list/CrudJsonEditor.vue';
import CrudLink from '@gid/vue-common/components/crud-list/CrudLink.vue';
import { FieldsDescription } from '@gid/vue-common/components/crud-list/CrudEditForm.vue';
import CrudMultiSelect from '@gid/vue-common/components/filters/CrudMultiSelect.vue';
import CrudPicklistMultiSelect from '@gid/vue-common/components/filters/CrudPicklistMultiSelect.vue';
import { getFields } from '@gid/vue-common/utils';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BIconPlus, BIconQuestionCircle, BIconDash } from 'bootstrap-vue';

export interface Transformer {
  fieldName: string;
  newValue: string;
}

@Component({
  name: 'BulkEditFields',
  components: {
    BIconPlus,
    BIconDash,
    BIconQuestionCircle,
    CrudMultiSelect,
    CrudEditArrayForm: () =>
      import('@gid/vue-common/components/crud-list/CrudEditArrayForm.vue'),
    ComponentWithPathWrapper,
    CrudPicklistMultiSelect,
    CrudCheckBox,
    CrudNumberInput,
    CrudRelationLinks,
    CrudRelationQuickLink,
    CrudJsonEditor,
    CrudHtmlEditor,
    CrudFileInput,
    CrudEnumPicker,
    CrudLink,
    CrudRichTextEditRichTextEditor,
  },
})
export default class RelationCloneConfig<Model> extends Vue {
  @Prop({ required: true, type: Function }) modelConstructor!: {
    new (...args: any[]): Model;
  };
  @Prop({ type: Function }) parentModelConstructor!: {
    new (...args: any[]): Model;
  };

  editFields: Transformer[] = [];
  selectedFieldName: string = '';
  selectOptions: any[] = [];
  filteredFields: FieldsDescription[] = [];
  parents: Function[] = [];
  disabled = false;
  entityId = '';

  @Watch('editFields', { deep: true })
  watchEditFields() {
    this.emitInput();
  }

  get entityName() {
    return this.modelConstructor.name;
  }

  get getSelectOptions() {
    return this.fields.map((field) => field.name);
  }

  get fields(): FieldsDescription[] {
    return getFields({ isBulkEdit: true, callerComponent: this });
  }

  created() {
    this.selectOptions = this.getSelectOptions;
  }

  addEditField() {
    this.selectOptions = this.selectOptions.filter(
      (o) => o !== this.selectedFieldName,
    );

    this.filteredFields = [
      ...this.filteredFields,
      ...this.fields.filter((field) => field.name === this.selectedFieldName),
    ];

    this.editFields.push({
      fieldName: this.selectedFieldName,
      newValue: '',
    });

    this.selectedFieldName = '';
  }

  removeEditField(fieldName: string) {
    this.selectOptions = [
      ...this.getSelectOptions,
      this.fields.find((field) => field.name === fieldName)?.name,
      ...this.selectOptions,
    ];
    this.filteredFields = this.filteredFields.filter(
      (field) => field.name !== fieldName,
    );
  }

  updateInput(fieldName: string, $event: { valuePath: string; value: string }) {
    const transformer = this.editFields.find(
      (transformer) => transformer.fieldName === fieldName,
    );
    if (!transformer) return;
    transformer.newValue = $event.value;
  }

  valueForField(fieldName: string) {
    const transformer = this.editFields.find(
      (transformer) => transformer.fieldName === fieldName,
    );

    return transformer?.newValue;
  }

  emitInput() {
    this.$emit('input', this.editFields);
  }
}
