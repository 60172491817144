
import {
  DataUiBlueprints,
  SimplifiedRelationType,
  SimplifiedColumnType,
} from '@gid/models';
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { BIconPlus, BIconQuestionCircle } from 'bootstrap-vue';

@Component({
  name: 'RelationCloneConfig',
  components: {
    BIconPlus,
    BIconQuestionCircle,
  },
})
export default class RelationCloneConfig<Model> extends Vue {
  @Prop({ required: true, type: Function }) modelConstructor!: {
    new (...args: any[]): Model;
  };
  @Prop({ type: Function }) parentModelConstructor!: {
    new (...args: any[]): Model;
  };

  relations: any = [];
  transformers: any = [];
  currentStringColumn: string | null = null;

  @Watch('relations', { deep: true })
  watchRelations(v) {
    this.emitInput();
  }

  @Watch('transformers', { deep: true })
  watchTransformers(v) {
    this.emitInput();
  }

  get currentName() {
    return this.modelConstructor.name;
  }

  get stringColumns() {
    const columns = DataUiBlueprints.get(this.modelConstructor).groups?.table;
    if (!columns) {
      return [];
    }
    return Object.values(columns)
      .filter(
        (c) => !c.isRelation && !c.isPrimary && c.columnType.type === 'text',
      )
      .map((c) => c.propertyName);
  }

  get relationColumns() {
    const columns = DataUiBlueprints.get(this.modelConstructor).groups?.table;
    if (!columns) {
      return [];
    }
    return Object.values(columns)
      .filter(
        (c) =>
          c.isRelation &&
          c.relationType !== SimplifiedRelationType.MANY_TO_MANY &&
          c.relationEntity !== this.parentModelConstructor,
      )
      .map((c) => ({
        property: c.propertyName,
        name:
          typeof c.relationEntity === 'string'
            ? c.relationEntity
            : c.relationEntity.name,
        model: c.relationEntity,
        enabled: false,
        relationsEnabled: false,
        allowCloning: c.allowCloning,
        config: {},
      }));
  }

  created() {
    this.relations = this.relationColumns;
  }

  addTransformer() {
    this.transformers.push({
      column: this.currentStringColumn,
      findRegex: '',
      replaceWith: '',
    });
  }

  emitInput() {
    this.$emit('input', {
      transformers: this.transformers,
      relations: this.relations
        .filter((r) => r.enabled)
        .map((r) => ({
          property: r.property,
          config: r.config,
        })),
    });
  }
}
