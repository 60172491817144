
import {
  CrudList,
  ModelWithId,
} from '@gid/vue-common/store/shared/crud-list.module';
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ImporterModal from './ImporterModal.vue';
import CloneModal from './CloneModal.vue';
import BulkEditModal from './BulkEditModal.vue';

@Component({
  computed: mapGetters(['access_token']),
  components: {
    ImporterModal,
    CloneModal,
    BulkEditModal,
  },
})
export default class ImportExportMenu<Model extends ModelWithId> extends Vue {
  @Ref() importModal!: ImporterModal;
  @Ref() cloneModal!: CloneModal;
  @Ref() bulkEditModal!: BulkEditModal;

  @Prop({ type: Object }) readonly store!: CrudList<Model>;
  access_token!: string;

  exportCurrentScreen(format) {
    const currentScreenUrl = this.store.currentScreenUrl;
    const path = `/data-api/export/${format}/${currentScreenUrl.entityPath}?${currentScreenUrl.noPagingQueryString}&jwt=${this.access_token}`;
    window.open(path);
  }

  refresh() {
    // TODO
  }

  importFileModal() {
    this.importModal.open();
  }

  cloneDataModal() {
    this.cloneModal.open();
  }

  openBulkEditModal() {
    this.bulkEditModal.open();
  }
}
